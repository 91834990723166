<template>
  <div class="undefid-cHeader">
    <img
      src="@/assets/images/left.png"
      style="width: 20px"
      @click="back()"
      class="undefid-left"
    />
    <div class="undefid-title">{{ title }}</div>
    <div class="undefid-rightNav" v-if="rightNav" @click="clickRightNav()">{{rightNav}}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    rightNav: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    back(){
      window.history.back()
    },
    clickRightNav(){
      this.$emit('handleClickRightNav');
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.undefid-cHeader {
  width: 100%;
  height: 90px;
  background: url('@/assets/images/nav_bg.png') 0 -380px no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  .undefid-left {
    width: 20px;
    position: absolute;
    top: 15px;
    left: 15px;
  }
  .undefid-title{
    height: 48px;
    line-height: 48px;
    color:#fff;
    font-size: 16px;
    padding-left: 50px;
    font-weight: bold;
    flex:1;
  }
  .undefid-rightNav{
    height: 38px;
    line-height: 38px;
    color:#fff;
    font-size: 12px;
    margin-right: 10px;
  }
}
</style>